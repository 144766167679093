import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { EMPTY, from, Observable, of, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CustomHeader } from '../models/header.enum';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SwPush, SwUpdate, VersionEvent } from '@angular/service-worker';
import { Notification } from '../models/notification.model';
import { environment } from '../../../environments/environment';
import { SnackComponent } from '../ui/snack/snack.component';
import { DOCUMENT } from '@angular/common';
import { msgType, SnackData } from '../ui/snack/snack-data.model';
export const DEFAULT_SCHEME = 'light dark'

@Injectable({
    providedIn: 'root',
})

export class NotificationService {
    public busy = signal(false);
    private readonly http = inject(HttpClient);
    private readonly snack = inject(MatSnackBar);
    private readonly swUpdate = inject(SwUpdate);
    private readonly swPush = inject(SwPush);
    private readonly document = inject(DOCUMENT);

    constructor() {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => { // TODO: Angular way
            console.log(event)
            if (this.getDefaultTheme() === DEFAULT_SCHEME) {
                this.document.body.className = event.matches ? "dark" : "light";
            }
        });
    }

    
    getAll(): Observable<Notification[]> {
        return this.http.get<Notification[]>(`${environment.urls.api}notifications`);
    }


    getCount(userId: number): Observable<number> {
        return timer(0, 30e3)
            .pipe(
                switchMap(() => {
                    return this.http.get<number>(
                        `${environment.urls.api}notification/count/${userId}`,
                        { headers: { [CustomHeader.SkipLoadingHeader]: '' } }
                    ).pipe(
                        tap(t => this.setBadge(t)),
                        catchError(() => EMPTY),
                    );
                })
            );
    }


    close(notificationId: number): Observable<void> {
        return this.http.put<void>(`${environment.urls.api}notification`, notificationId);
    }


    showMsg(msg: string, type: msgType = 'info', duration = 5000): MatSnackBarRef<SnackComponent> {
        const data: SnackData = { msg, type };
        if (type === 'error') {
            this.vibrate();
        }
        return this.snack.openFromComponent(SnackComponent, { duration, data });
    }


    checkForUpdates(): Observable<VersionEvent> {
        return this.swUpdate.isEnabled ?
            this.swUpdate.versionUpdates.pipe(
                tap(sw => {

                    switch (sw.type) {
                        case 'VERSION_DETECTED':
                            this.showMsg(`Downloading new version...`, `warn`);
                            console.info(`Downloading new app version: ${sw.version.hash}`);
                            break;

                        case 'VERSION_READY':
                            this.showMsg(`New version of the application available.`, `info`).afterDismissed().subscribe(
                                () => window.location.reload()
                            );
                            console.info(`Current app version: ${sw.currentVersion.hash}`);
                            console.info(`New app version ready for use: ${sw.latestVersion.hash}`);
                            break;

                        case 'VERSION_INSTALLATION_FAILED':
                            this.showMsg(`Failed to install new app version`, `error`);
                            console.error(`Failed to install app version: ${sw.error}`);
                            break;
                    }
                })
            ) : EMPTY;
    }


    vibrate(time = 100): void {
        if ('vibrate' in navigator && navigator.userActivation.isActive) {
            navigator.vibrate(time);
        }
    }


    setBadge(badge: number): void {
        if ("setAppBadge" in navigator) {
            window.navigator.setAppBadge(badge);
        }
    }


    requestNotifications(data?: object): Observable<void> {
        return this.swPush.isEnabled ?
            from(this.swPush.requestSubscription({
                serverPublicKey: environment.push.publicKey
            })).pipe(
                switchMap(sub => this.http.post<void>(`${environment.push.api}/sub`,
                    {
                        ...sub.toJSON(),
                        userAgent: navigator.userAgent,
                        ...data
                    },
                    { headers: { [CustomHeader.SkipNotificationHeader]: '' } }
                )
                ))
            : EMPTY;
    }


    getNotificationsState(): Observable<PermissionState> {
        return ("permissions" in navigator) ?
            from(
                navigator.permissions.query({ name: "notifications" })
                    .then(result => result.state)
            )
            : of('denied')
    }



    setTheme(theme: string): void {
        this.document.body.style.colorScheme = theme;
        this.document.body.className = theme.replace(' ', '-');
        localStorage.setItem('color-scheme', theme);
    }


    setDefaultTheme(): void {
        const theme = localStorage.getItem('color-scheme') || DEFAULT_SCHEME;
        this.document.body.className = theme.replace(' ', '-');
        this.document.body.style.colorScheme = theme;
    }


    getDefaultTheme(): string {
        return localStorage.getItem('color-scheme') || DEFAULT_SCHEME;
    }

}
