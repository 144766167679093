<div class="placeholder">
    @switch (data.type) {
        @case ("info") {
            <mat-icon class="info">check_circle</mat-icon>
        }
        @case ("notify") {
            <mat-icon class="notify">info</mat-icon>
        }
        @case ("warn") {
            <mat-icon class="warn">warning</mat-icon>
        }
        @case ("error") {
            <mat-icon class="error">error</mat-icon>
        }
        }
    <div [innerHTML]="data.msg"></div>
</div>