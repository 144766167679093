import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackData } from './snack-data.model';

@Component({
  selector: 'app-snack',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './snack.component.html',
  styleUrl: './snack.component.scss'
})
export class SnackComponent {
  data = inject<SnackData>(MAT_SNACK_BAR_DATA);
}
